<template>
	<div>
		<a-spin :spinning="loading">
			<Header :title="'场次座位 当前演出：' + name" @back="onBackSession(false)"></Header>
			<a-form class="ui-form" ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item label="座位" name="seatName" class="ui-form__item">
						<a-input v-model:value="formState.seatName" placeholder="请输入"></a-input>
					</a-form-item>
					<a-form-item label="座位编号" name="seatCode" class="ui-form__item">
						<a-input v-model:value="formState.seatCode" placeholder="请输入"></a-input>
					</a-form-item>
					<a-form-item label="票档名称" name="ticketName" class="ui-form__item">
						<a-input v-model:value="formState.ticketName" placeholder="请输入"></a-input>
					</a-form-item>
					<a-form-item label="票档编号" name="ticketCode" class="ui-form__item">
						<a-input v-model:value="formState.ticketCode" placeholder="请输入"></a-input>
					</a-form-item>
					<a-form-item label="分配票号" name="userTicketCode" class="ui-form__item">
						<a-input v-model:value="formState.userTicketCode" placeholder="请输入"></a-input>
					</a-form-item>
					<!-- <a-form-item label="用户ID" name="userId" class="ui-form__item">
						<a-input v-model:value="formState.userId" placeholder="请输入"></a-input>
					</a-form-item> -->
					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入"></a-input>
					</a-form-item>
					<a-form-item label="分配人" name="divideUserName" class="ui-form__item">
						<a-input v-model:value="formState.divideUserName" placeholder="请输入"></a-input>
					</a-form-item>
					<a-form-item label="状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择状态" allow-clear
							style="width: 200px;">
							<a-select-option :value="0">待分配</a-select-option>
							<a-select-option :value="1">已分配</a-select-option>
							<a-select-option :value="3">已冻结</a-select-option>
							<a-select-option :value="4">已售后</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="分配时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
						<!-- <a-button type="primary" style="margin-right: 10px;">导出</a-button> -->
						<a-button v-permission="['performance_manage_list_session_seatImport']" type="primary" style="margin-right: 10px;" @click="onImport">导入管理</a-button>
						<a-button v-permission="['performance_manage_list_session_seatDivide']" type="primary" style="margin-right: 10px;" @click="onAutoDivide">自动分配座位</a-button>
						<a-button v-permission="['performance_manage_list_session_seatSend']" type="primary" style="margin-right: 10px;" @click="onSendAll">发放票给用户</a-button>
						<a-button v-permission="['performance_manage_list_session_seatClear']" type="primary" style="margin-right: 10px;" danger @click="onClearAll">全部座位重置</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 10px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 1800 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div v-if="record.userId">
								<!-- <div>用户id：{{ record.userId }}</div> -->
								<div>用户名称：{{ record.userName || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'time'">
							<div>{{ transDateTime(record.divideTime) }}</div>
						</template>
						<template v-if="column.key === 'status'">
							<div>{{ ['待分配', '已分配', '', '已冻结', '已售后'][record.status] }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['performance_manage_list_session_seatDivide']" v-if="record.status !== 3" @click="onEdit(record)">
											<a-menu-item>
												分配座位
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_session_seatDisabled']" v-if="record.status === 3" @click="onChangeState(record)">
											<a-menu-item>
												解冻
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_session_seatDisabled']" v-else @click="onChangeState(record)">
											<a-menu-item>
												冻结
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="showModal" title="分配座位" width="750px">
			<template #footer>
				<a-button tyype="primary" danger :loading="loading" @click="onClear">清除座位分配</a-button>
				<a-button @click="onCancel" :loading="loading">关闭</a-button>
				<a-button type="primary" :loading="loading" @click="onDivide">确定</a-button>
			</template>
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="modelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18 }">
					<a-form-item label="手机号" name="phone" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.phone" placeholder="请输入手机号"></a-input>
					</a-form-item>
					<a-form-item label="票号" name="ticketNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.ticketNo" placeholder="请输入票号"></a-input>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
		
		<a-modal v-model:visible="importModal" title="导入管理" width="759px" @ok="onImportOk">
			<a-spin :spinning="loading">
				<a-form ref="importFormRef" :model="importModelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }">
					<a-form-item label="导入类型">
						<a-radio-group v-model:value="importModelRef.type" @change="onImportTypeChange">
							<a-radio value="performSeatImportStrategy">导入座位</a-radio>
							<a-radio value="performSeatDivideImportStrategy">导入分配座位</a-radio>
						</a-radio-group>
					</a-form-item>
					
					<div>
						<a-form-item label="任务名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="importModelRef.title" placeholder="请输入"></a-input>
						</a-form-item>
						
						<a-form-item label="点击下载">
							<a v-if="importModelRef.type == 'performSeatImportStrategy'" href="/dashboard/导入演出场次座位模板.xlsx">Excel模板</a>
							<a v-if="importModelRef.type == 'performSeatDivideImportStrategy'" href="/dashboard/导入演出场次分配座位模板.xlsx">Excel模板</a>
						</a-form-item>
						
						<a-form-item label="选择文件">
							<a-upload v-model:file-list="fileList" list-type="picture" action="/admin/ajaxUpload.do"
									  accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload" @change="onUploadChange">
								<a-button>
									<Icon icon="UploadOutlined"></Icon>
									上传
								</a-button>
							</a-upload>
						</a-form-item>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { importTaskSave } from '@/service/modules/task.js';
	import {
		getPerformSeatList,
		disabledPerformSeat,
		clearSchedulePerformSeat,
		performSeatSend,
		clearPerformSeat,
		autoDividePerformSeat,
		dividePerformSeat
	} from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			Header,
		},
		props: {
			id: { // 演出ID
				type: Number,
				default: 0
			},
			scheduleId: { // 场次ID
				type: Number,
				default: 0
			},
			name: { // 演出名称
				type: String,
				default: ''
			}
		},
		data() {
			return {
				loading: false,
				searchData: {},
				time: [],
				formState: {},
				list: [],
				columns: [{
					title: '座位',
					dataIndex: 'seatName',
				}, {
					title: '座位编号',
					dataIndex: 'seatCode',
				}, {
					title: '座位序号',
					dataIndex: 'serialCode',
				}, {
					title: '票档编号',
					dataIndex: 'ticketCode',
				}, {
					title: '票档名称',
					dataIndex: 'ticketName',
				}, {
					title: '分配票号',
					dataIndex: 'userTicketCode',
				}, {
					title: '用户信息',
					key: 'userInfo',
				}, {
					title: '分配时间',
					key: 'time',
					width: 120
				}, {
					title: '分配人',
					dataIndex: 'divideUserName',
					width: 120
				}, {
					title: '状态',
					key: 'status',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120,
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				editInfo: {},
				modelRef: {},
				importModal: false,
				importModelRef: {},
				fileList: [],
			}
		},
		created() {
			if (this.scheduleId) {
				this.getData();
			}
		},
		methods: {
			onBackSession(isRef) {
				this.$emit('back', isRef);
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformSeatList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					scheduleId: this.scheduleId,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount
				}
			},
			onChangeState(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.status === 3 ? '解冻' : '冻结' }该选项吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await disabledPerformSeat({
							id: item.id,
							status: item.status === 3 ? 0 : 3
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.status === 3 ? '解冻' : '冻结' }成功！`);
							this.getData();
						}
					}
				})
			},
			onSendAll() {
				this.$confirm({
					title: '提示',
					content: '确定发放票给用户吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await performSeatSend({
							scheduleId: this.scheduleId
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('发放成功！');
						}
					}
				})
			},
			onEdit(item) {
				this.modelRef = {};
				this.editInfo = item;
				this.showModal = true;
			},
			onClearAll() {
				this.$confirm({
					title: '提示',
					content: '确定重置全部已分配的座位吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await clearSchedulePerformSeat({
							scheduleId: this.scheduleId
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('清除成功！');
						}
					}
				})
			},
			onCancel(){
				this.$refs.formRef.resetFields();
				this.modelRef = {};
				this.showModal = false;
			},
			onClear() {
				this.$confirm({
					title: '提示',
					content: '确定清除该座位的分配信息吗？',
					onOk: async ()=> {
						this.loading = true;
						let ret = await clearPerformSeat({
							id: this.editInfo.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('清除成功！');
							this.onCancel();
							this.getData();
						}
					}
				})
			},
			onDivide() {
				this.$refs.formRef.validate().then(()=> {
					this.$confirm({
						title: '提示',
						content: '确定对该座位进行分配吗？',
						onOk: async() => {
							this.loading = true;
							let ret = await dividePerformSeat({
								id: this.editInfo.id,
								...this.modelRef
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('分配成功！')
								this.onCancel();
								this.getData();
							}
						}
					})
				})
			},
			onAutoDivide() {
				this.$confirm({
					title: '提示',
					content: '确定自动分配座位吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await autoDividePerformSeat({
							scheduleId: this.scheduleId
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('自动分配座位成功！');
							this.getData();
						}
					}
				})
			},
			onImport() {
				this.importModelRef = {
					type: 'performSeatImportStrategy'
				};
				this.fileList = [];
				this.importModal = true;
			},
			onImportTypeChange() {
				this.fileList = [];
			},
			onImportOk() {
				this.$refs.importFormRef.validate().then(() => {
					if (this.fileList.length === 0) {
						this.$message.warn('请先选择模板文件');
						return;
					}
					this.$confirm({
						title: '提示',
						content: '确定执行导入吗？',
						onOk: async () => {
							this.loading = true;
							let postData = JSON.parse(JSON.stringify(this.importModelRef));
							let ret = await importTaskSave(postData)
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请前往【系统-任务中心-导入任务】页查看导入结果');
								this.importModal = false;
								this.importModelRef = {};
							}
						}
					})
				})
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.importModelRef.filePath = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 10;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许10M');
				}
				return isLt1M;
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
	}

	.ui-form__item {
		margin-right: 30px;
	}
</style>