<template>
	<div v-show="!showChild&&!showEvaluate&&!showSession">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-col>
						<a-form-item class="ui-form__item" label="演出名称" name="name">
							<a-input v-model:value="formState.name" placeholder="请输入演出名称"></a-input>
						</a-form-item>
					</a-col>
					<a-col>
						<a-form-item class="ui-form__item" label="演出分类" name="classifyId">
							<preformClassify v-model:value="formState.classifyId"></preformClassify>
						</a-form-item>
					</a-col>
					<a-col>
						<a-form-item class="ui-form__item" label="演出标签" name="tagId">
							<a-select v-model:value="formState.tagId" placeholder="请选择" style="width: 200px;" allow-clear>
								<a-select-option v-for="item in tagList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
				</a-row>
				<a-row v-show="showAll">
					<!-- <a-col>
						<a-form-item label="类型" name="isSelfSupport" class="ui-form__item">
							<a-select v-model:value="formState.isSelfSupport" placeholder="请选择类型" allow-clear
								style="width: 200px;">
								<a-select-option :value="1">自营</a-select-option>
								<a-select-option :value="0">非自营</a-select-option>
							</a-select>
						</a-form-item>
					</a-col> -->
					<!-- <a-col>
						<a-form-item label="是否演出攻略推荐" name="isRecommend" class="ui-form__item">
							<a-select v-model:value="formState.isRecommend" placeholder="请选择是否演出攻略推荐" allow-clear style="width: 200px;">
								<a-select-option :value="0">否</a-select-option>
								<a-select-option :value="1">是</a-select-option>
							</a-select>
						</a-form-item>
					</a-col> -->
					<a-col>
						<a-form-item label="状态" name="isDisabled" class="ui-form__item">
							<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 200px;">
								<a-select-option :value="0">启用</a-select-option>
								<a-select-option :value="1">禁用</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['performance_manage_list_add']" type="primary" @click="onAddPerformance">新增演出</a-button>
						<a style="margin-left: 20px" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{x: 1300}">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'posterUrl'">
							<a-image :src="record.posterUrl" style="width: 100px;"></a-image>
						</template>
						<template v-if="column.key === 'isSelfSupport'">
							{{ record.isSelfSupport ? '自营' : '非自营' }}
						</template>
						<template v-if="column.key === 'isRecommend'">
							{{ record.isRecommend ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							<div>{{ record.isDisabled ? '已禁用' : '已启用' }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div @click="onView(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_disabled']" v-if="record.isDisabled" @click="onChangeState(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_disabled']" v-else @click="onChangeState(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<!-- <div @click="onViewShow(record)">
											<a-menu-item>
												查看展示评价
											</a-menu-item>
										</div>
										<div @click="onViewAll(record)">
											<a-menu-item>
												查看所有评价
											</a-menu-item>
										</div> -->
										<div v-permission="['performance_manage_list_session']" @click="onSession(record)">
											<a-menu-item>
												演出场次
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<temp v-if="showChild" :perState="perState" :id="id" @back="onBack"></temp>
	<evaluateShow v-if="showEvaluate" @back="onBack"></evaluateShow>
	<session v-if="showSession" :id="id" :name="name" @back="onBack"></session>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import evaluateShow from './evaluateShow.vue';
	import session from './session.vue';
	import preformClassify from '@/views/performance/components/classify/index.vue';
	import {
		getPerformList,
		disabledPerform,
		getPerformTagList,
	} from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			temp,
			evaluateShow,
			session,
			preformClassify
		},
		data() {
			return {
				id: 0,
				name: '',
				loading: false,
				showAll: false,
				tagList: [],
				searchData: {},
				formState: {},
				perState: 0,
				showChild: false,
				showModal: false,
				showEvaluate: false,
				showSession: false,
				modelRef: {},
				list: [],
				columns: [{
					title: '演出名称',
					dataIndex: 'name',
				}, {
					title: '演出海报',
					key: 'posterUrl',
				}, {
					title: '演出分类',
					dataIndex: 'classifyName',
				}, {
					title: '演出标签',
					dataIndex: 'tagName',
				}, {
					title: '演出简介',
					dataIndex: 'description',
				}, {
					title: '演出城市',
					dataIndex: 'city'
				}, 
				// {
				// 	title: '是否演出攻略推荐',
				// 	key: 'isRecommend',
				// 	width: 150
				// }, 
				{
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getPerformTagList();
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showChild = false;
				this.showEvaluate = false;
				this.showSession = false;
				if (isRef) {
					this.getData();
				}
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			async getPerformTagList() {
				let ret = await getPerformTagList({
					page: 1,
					pageSize: 99999,
					isDisabled: 0
				})
				if (ret.code === 200) {
					this.tagList = ret.data.list;
				}
			},
			onAddPerformance() {
				this.id = 0;
				this.name = '';
				this.showChild = true
				this.perState = 0
			},
			onEdit(item) {
				this.id = item.id;
				this.name = '';
				this.showChild = true
				this.perState = 2
				this.modelRef = item
			},
			onSubmit() {
				this.showModal = false
				this.modelRef = {}
			},
			onCancel() {
				this.modelRef = {}
			},
			onView(item) {
				this.id = item.id;
				this.perState = 1
				this.showChild = true
			},
			onViewShow(item) {
				this.showEvaluate = true
			},
			onViewAll(item) {
				this.$router.push({
					path: '/comment/list'
				})
			},
			onSession(item) {
				this.id = item.id;
				this.name = item.name;
				this.showSession = true
			},
			onChangeState(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '启用' : '禁用' }该选项吗？`,
					onOk: async() => {
						this.loading = true;
						let ret = await disabledPerform({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.isDisabled ? '启用' : '禁用' }成功！`);
							this.getData();
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>